
<template>
<div class="container user-register">
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-5">
      <div class="card border border-2">
        <div class="card-body p-4 px-md-5 py-lg-6 px-lg-6">
          <div class="">
              <h3 class="text-primary font-weight-bold align-items-center">
                  <span class="">Sign Up</span>
              </h3>
              <p class="mb-3 small" style="color:#999999">
                Create account to continue
              </p>
          </div>
            <form class="mb-6">
              <div class="form-group">
                  <input type="text" v-model="data.username" v-validate="{regex: '^\\+?([0-9a-z_-]+)$' }"  name="username" class="form-control"
                  placeholder="Enter username" :class="{ 'is-invalid': errors.has('username') }">
                  <span class="text-danger invalid-feedback"  v-show="errors.has('username')">{{ errors.first('username') }}</span>
              </div>
              <div class="form-group">
                  <input type="email" v-model="data.email" v-validate="{ required: true, email: true }"  class="form-control"
                    name="email" placeholder="Enter email" :class="{ 'is-invalid': errors.has('email') }">
                  <span class="text-danger invalid-feedback"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
              </div>
              <div class="form-group">
                  <select required v-model="data.country_id" v-validate="'required'" name="country" class="custom-select custom-select-sm" >
                      <option value="" disabled>--Select country--</option>
                      <option
                          v-for="(country, conIndex) in countries"
                          :value="country.id"
                          :key="conIndex" > {{country.name}} </option>
                  </select>
                  <span class="text-danger" v-show="errors.has('country')">{{ errors.first('country') }}</span>
              </div>
              <div class="form-group mb-5">
                  <multiselect :options="categories.map(c => c.id)"
                      :custom-label="parseCategory"
                      searchable
                      :multiple="true"
                      :close-on-select="false"
                      v-model="data.categories" placeholder="--select category--"></multiselect>
              </div>
              <div class="form-group mb-5">
                  <input type="password" v-validate="'required|min:6|max:100'" v-model="data.password" class="form-control" name="password" placeholder="Enter your password" :class="{ 'is-invalid': errors.has('password') }">
                  <span class="text-danger invalid-feedback" v-show="errors.has('password')">{{ errors.first('password') }}</span>
              </div>
              <p class="mt-3 mb-2 font-14">By clicking Sign up, you agree to our <a class="text-success" :href="absoluteUrl('/terms-conditions')">terms and conditions.</a></p>
              <button @click.prevent="submitData()" class="btn btn-block btn-success"  type="button">
                  Sign Up
              </button>
          </form>
          <p class="mt-5 mb-0 text-center" style="color:#999999">
            Already have an account?  <router-link to="/login" class="text-success font-weight-bold" >Signin</router-link>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components:{
      Multiselect
  },
  data() {
    return {
      data: {
        username: "",
        email: "",
        country_id: "",
        categories: [],
        password: "",
        role: "worker",
      },
      passwordType: "password",

    }
  },
  computed: {
    countries(){
        return this.$store.state.countries;
    },
    categories(){
        return this.$store.state.categories;
    }
  },
  methods: {
    parseCategory(id){
      let cat = this.categories.find(item => item.id == id);
      if(cat){ return cat.name; }
    },
    passwordVisibility(){
        this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
    },
    submitData(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data));
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(this.absoluteUrl('/api/register'), formData)
        .then((response)=> {
          this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.resetForm()
            this.$store.commit("SET_AUTH_TOKEN", response.data.data)
          }
        })
      });
    },
    resetForm(){
      this.data = {
        username: "",
        email: "",
        country_id: "",
        categories: [],
        password: "",
        role: "worker",
      }
      this.$validator.reset()
    },
   },
   created(){
    this.$store.dispatch("fetchCategories")
   }
  }
</script>
